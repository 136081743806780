import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import getImage from '@solid-ui-components/utils/getImage'

const ContentImages = ({
  content: { images },
  reverse,
  imagePosition,
  imageFit,
  imageEffect,
  loading,
  threshold,
  imgStyle
}) =>
  images ? (
    <>
      <Reveal
        effect={images?.[0]?.effects?.[0] || imageEffect || (reverse ? 'fadeInRight' : 'fadeInLeft')}
        threshold={threshold}
        css={css({
          textAlign:
            imagePosition === 'center' ? 'center' : reverse ? `right` : `left`,
          lineHeight: 0,
        })}
      >
        {images?.[0]?.src && (
          <Img
            image={getImage(images[0].src)}
            alt={images[0].alt}
            loading={loading}
            objectFit={imageFit}
            css={css(imgStyle)}
          />
        )}
      </Reveal>
      {images?.slice(1)?.map(
        ({ src, position = {}, effects = [], alt }, index) =>
          src && (
            <Reveal
              key={`item-${index}`}
              effect={effects[0] || undefined}
              delay={0.5}
              threshold={1}
              css={css({
                ...position,
                position: `absolute`,
                display: [`none`, `block`]
              })}
            >
              <Reveal
                effect={effects[1] || undefined}
                style={{ backfaceVisibility: `hidden` }}
              >
                <Img image={getImage(src)} alt={alt} css={css(imgStyle)} />
              </Reveal>
            </Reveal>
          )
      )}
    </>
  ) : null

ContentImages.defaultProps = {
  loading: 'lazy',
  threshold: 0.7
}

export default ContentImages
