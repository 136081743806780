import React, { useState, useEffect, createContext } from 'react';
import { toast } from 'react-toastify';
import { useStaticQuery, graphql, navigate } from 'gatsby';
// import PageLoading from '@solid-ui-components/PageLoading';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import { login, auth, logOut } from '@helpers/userAuth';

export const AppContext = createContext();

export default function AppWrapper({ children }) {
  const { site, allBlockContent } = useStaticQuery(query);
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [pageLoading, setPageLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(typeof window !== 'undefined' && !!localStorage.getItem('refreshToken'));

  useEffect(() => {
    const preSelectedLanguage = typeof window !== 'undefined' ? localStorage?.getItem('selectedLanguage') : 'en';
    setSelectedLanguage(preSelectedLanguage);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage?.setItem('selectedLanguage', selectedLanguage);
    };
  }, [selectedLanguage]);

  useEffect(() => {
    const handleAuth = async () => {
      if (localStorage?.getItem('refreshToken') !== undefined) {
        try {
          const isAuthenticated = await auth();
          setAuthenticated(isAuthenticated);
        } catch(error) {
          if (error.__type === 'NotAuthorizedException') {
            handleLogout(null, true);
          } else {
            handleLogout(null, true);
          };
        };
      };
    };
    handleAuth();
  }, []);

  const handleLogin = async ({ email, password }) => {
    const messages = content[`login-messages.${selectedLanguage}`];
    try {
      const status = await login(email, password);
      setAuthenticated(status);
      if (status) {
        toast.success(messages?.kicker?.text,
          { position: 'top-center', autoClose: 1500 }
        );
      };
      return status;
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        // Handle NotAuthorizedException error
        toast.error(messages?.title?.text);
      } else if (error.code === 'UserNotFoundException') {
        // Handle UserNotFoundException error
        toast.error(messages?.text_1?.text, );
      } else if (error.code === 'PasswordResetRequiredException') {
        // Handle PasswordResetRequiredException error
        toast.error(messages?.text_2?.text);
      } else {
        console.log('Error:', error.code);
        toast.error(messages?.text_3?.text);
      }
      setAuthenticated(false);
      throw error;
    }
  };

  const handleLogout = async (e, tokenInvalid = false) => {
    const messages = content[`logout-messages.${selectedLanguage}`];
    setPageLoading(true);
    try {
      await logOut(tokenInvalid);
      setPageLoading(false);

      if (!tokenInvalid) {
        toast.info(messages?.title?.text, { position: 'top-center', autoClose: 2000 });
        await new Promise(resolve => setTimeout(resolve, 2000 ));
      };

      navigate('/');
    } catch (error){}
  };

  function switchLanguage(lang) {
    setSelectedLanguage(lang);
  };

  return (
    <AppContext.Provider
      value={{
        authenticated,
        handleLogin,
        handleLogout,
      }}
    >
      {
      // pageLoading ?
      //   <PageLoading />
      //   :
        React.Children.map(children, (child) => {
          return React.cloneElement(child,
            {
              languages: site?.siteMetadata?.languages.langs,
              selectedLanguage,
              switchLanguage,
              ...child.props
            }
          );
        })
      }
    </AppContext.Provider>
  );
}

const query = graphql`
  query siteMetaDataBlockContent {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allBlockContent(
      filter: {
        page: {
          in: ["site/shared/authentication/login-messages", "site/shared/authentication/logout-messages"]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
