import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, css, Spinner, useThemeUI } from 'theme-ui'
import { toast } from 'react-toastify'
import { BiCheckCircle } from 'react-icons/bi';
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import FormCheckbox from '@solid-ui-components/ContentForm/FormCheckbox'
import FormInput from '@solid-ui-components/ContentForm/FormInput'
import FormTel from '@solid-ui-components/ContentForm/FormTel'
import FormTextarea from '@solid-ui-components/ContentForm/FormTextarea'
import FormHidden from '@solid-ui-components/ContentForm/FormHidden'
import FormSelect from '@solid-ui-components/ContentForm/FormSelect'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm';

const styles = (theme) => ({
  form: {
    position: `relative`
  },
  responseOverlay: {
    position: `absolute`,
    backgroundColor: `transparent`,
    width: theme.sizes.full,
    height: `105%`,
    transition: `${theme.colors.background} 350ms ease-in`,
    textAlign: `center`,
    zIndex: -1,
    p: 3,
    top: 0,
    left: 0,
    active: {
      zIndex: 0,
      backgroundColor: `rgba(255,255,255,0.85)`
    }
  },
  buttonsWrapper: {
    display: `inline-flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    '.button-group-button + .button-group-link': {
      flex: `100%`,
      ml: 0,
      mt: 3
    }
  }
});

const ContentForm = ({
  id,
  form: { action, fields, buttons } = {},
  onSuccess = () => {}
}) => {
  const { theme } = useThemeUI();
  const { handleSubmit, submitting, success, formErrors } = useForm({ onSuccess })
  const { formValues, setFormValues } = useContext(FormContext)
  const formId = id

  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, submitting, setFormValues]);

  useEffect(() => {
    formErrors?.forEach(err => toast.error(err));
  }, [formErrors]);

  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    });
  }

  const onSubmit = e => {
    const values = formValues[formId];
    handleSubmit(e, { values, action });
  };

  return (
    <>
      <form
        // netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        css={css(styles(theme).form)}
        onSubmit={onSubmit}
        method='post'
        action={action}
        demo={action ? undefined : 'demo'}
      >
        <Box variant='forms.row'>
          {fields?.map(({ identifier, value, ...props }, index) => {
            let Component
            switch (props.type) {
              case 'PASSWORD':
              case 'EMAIL':
              case 'TEXT':
                Component = FormInput
                break
              case 'TEL':  
                Component = FormTel
                break
              case 'TEXTAREA':
                Component = FormTextarea
                break
              case 'CHECKBOX':
                Component = FormCheckbox
                break
              case 'SELECT':
                Component = FormSelect
                break
              case 'HIDDEN':
                Component = FormHidden
                break
              default:
                break
            }

            return (
              <Box
                key={`index-${index}-${identifier}`}
                variant={props.compact ? 'forms.compact' : 'forms.full'}
              >
                <Component
                  {...props}
                  key={identifier}
                  onChange={onChange}
                  name={identifier}
                  id={`${formId}.${identifier}`}
                  value={formValues?.[formId]?.[identifier] || value || undefined}
                />
              </Box>
            )
          })}
        </Box>
        <Box sx={{ textAlign: `center` }}>
          <ContentButtons
            content={buttons}
            wrapperStyles={styles(theme).buttonsWrapper}
            space={1}
          />
        </Box>
        <Box
          sx={{
            ...styles(theme).responseOverlay,
            ...((submitting || success) ? styles(theme).responseOverlay.active : {}),
          }}
        >
          {submitting && (
            <Reveal effect='fadeInDown'>
              <Spinner size='64' color='alpha' />
            </Reveal>
          )}
          {success === true && (
            <Reveal effect='fadeInDown'>
              <BiCheckCircle size='64' css={css({ color: theme.colors.success })} />
            </Reveal>
          )}
        </Box>
      </form>
    </>
  )
}

export default ContentForm

ContentForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
