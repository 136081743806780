import React, { useContext } from 'react';
import { Box } from 'theme-ui';
import Icon from '@solid-ui-components/ContentIcon';
import Divider from '@solid-ui-components/Divider';
import { ModalContext } from '@solid-ui-components/Modal';
import { TabsContext } from '@solid-ui-components/Tabs';
import { buildLinkProps } from '@solid-ui-components/ContentButtons';
import pageContextProvider from '@helpers/pageContextProvider'
import { AppContext } from '@helpers/appContext';

const styles = {
  horizontal: {
    '.button-group-link': {
      py: 3
    },
    '> * + a, > * + div, > * + button': {
      ml: [3, null, null, 4],
      mt: [4, 0]
    }
  },
  vertical: {
    display: `flex`,
    flexDirection: `column`,
    a: {
      mb: [3, 2],
      px: [2, 0]
    }
  }
}

const ContentButtons = ({
  content,
  space,
  variant,
  wrapperStyles,
  onClick,
}) => {
  const { setActiveModal } = useContext(ModalContext);
  const { setActiveTab } = useContext(TabsContext);

  const values = useContext(AppContext);
  const { location } = useContext(pageContextProvider);
  
  return content ? (
    <>
      <Divider space={space} />
      <Box sx={{ ...styles[variant], ...wrapperStyles }}>
        {content?.map(
          ({ type, text, link, target, variant, width, bg, icon }, index) => {
            const { Component, linkProps } = buildLinkProps({
              content: { type, link, target, variant },
              location,
              setActiveModal,
              setActiveTab,
              authenticated: values?.authenticated,
              handleLogout: values?.handleLogout
            })
            
            return (
              Component ?
                <Component
                  key={`item-${index}`}
                  variant={variant || 'primary'}
                  onClick={onClick}
                  sx={{ width, '::after': { bg, borderColor: bg } }}
                  {...linkProps}
                >
                  <Icon content={icon} size='xs' /> {text}
                </Component>
                :
                null
            )
          }
        )}
      </Box>
    </>
  ) : null
}

export default ContentButtons

ContentButtons.defaultProps = {
  variant: 'horizontal'
}