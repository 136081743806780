import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { Global } from '@emotion/core'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import baseTheme from '@solid-ui-theme'
import pageContextProvider from '@helpers/pageContextProvider'
import Header from "@solid-ui-blocks/Header/Block01"
import Footer from "@solid-ui-blocks/Footer/Block01"
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01"
import ContactModal from "@solid-ui-blocks/Modal/ContactModal"
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const Layout = ({
  children,
  languages,
  selectedLanguage,
  switchLanguage,
  pageContext,
  location,
  theme = {},
  showHeaderAndFooter = true
}) => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const headerContent = content[`header.${selectedLanguage}`],
    authenticationContent = content[`authentication.${selectedLanguage}`],
    contactContent = content[`contact.${selectedLanguage}`],
    footerContent = content[`footer.${selectedLanguage}`];
  
  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />

                {/* Page Header */}
                {showHeaderAndFooter &&
                  <Header
                    languages={languages}
                    switchLanguage={switchLanguage}
                    content={headerContent}
                  />
                }

                {/* Page Body */}
                <Box variant='layout.body'>{children}</Box>

                {showHeaderAndFooter && (
                  <>
                    {/* Modals */}
                    <ToastContainer
                      position="top-right"
                      autoClose={4000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick={false}
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                    <ModalWithTabs reverse content={authenticationContent} />
                    <ContactModal content={contactContent} />
                    {/* Page Footer */}
                    <Footer content={footerContent} />
                  </>
                )}
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  );
};

const query = graphql`
  query siteLayoutBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/shared/header"
            "site/shared/footer"
            "site/shared/contact"
            "site/shared/authentication"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

export default Layout
