import React from 'react'
import { Box, Select } from 'theme-ui'
// import Icon from '@solid-ui-components/ContentIcon'

const FormInput = ({ name, options, placeholder, icon, onChange, ...props }) => (
    <Box variant='forms.field' sx={{width:'100%'}}>
        <Select
            style={{
                minWidth:'350px',
                paddingLeft:'16px',
                border: 'none',
                fontSize:'15.5px'
            }}
            defaultValue=""
            onChange={(e) => onChange({ target : { name, value: e.target.value } })}
            {...props}
        >
            <option key="empty" value="" disabled>{placeholder?.text}</option>
            {options?.map(({ label, value }) => 
                <option key={value} value={value}>{label}</option>
            )}
        </Select>
    </Box>
)

export default FormInput

