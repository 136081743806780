import React, { useState, useEffect } from "react";
import { Link as GLink } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import Sticky from "react-sticky-el";
import { Container, Box, Flex, css, Select, useThemeUI } from "theme-ui";
import Reveal from "@solid-ui-components/Reveal";
import Drawer from "@solid-ui-components/Drawer";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import getImage from "@solid-ui-components/utils/getImage";

const styles = (theme) => ({
  wrapper: {
    position: `relative`,
    zIndex: 10,
    ".nav-container": {
      bg: theme.colors.headerBg,
      position: `fixed`,
      transition: `all 250ms ease-in`,
      overflow: 'hidden',
      py: 4,
    },
    ".nav-sticky .nav-container": {
      bg: theme.colors.headerActiveBg,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
      py: [3, 3, 2],
    },
    //Make buttons in header smaller
    ".button-group-link": {
      minWidth: 100,
      fontSize: 16,
      fontWeight: '600',
      ml: '0.9rem',
    },
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`,
    // height: [`6rem`, `7rem`], //prevent layout shift
  },
  logoContainer: {
    flexShrink: 0,
    mr: '0',
  },
  desktopMenu: {
    display: [`none`, 'none', `none`, 'block'],
    minWidth: `auto`,
    flexGrow: 1,
  },
  mobileMenu: {
    display: [`block`, 'block', `block`, 'none'],
  },
  logo: {
    maxWidth: "300px",
  },
  langsContainer: {
    marginLeft: "18px",
    fontSize: "16px",
    position: "relative",
    top: "-10px",
  },
});

const langSelect = {
  en: { name: "EN" },
  de: { name: "DE" },
  ja: { name: "JA" },
  zh: { name: "ZH" },
  ru: { name: "RU" },
  es: { name: "ES" },
  ko: { name: "KO" },
};

const HeaderBlock01 = ({
  content: { images, collection },
  menuJustify,
  languages,
  switchLanguage,
}) => {
  const { theme } = useThemeUI();
  //Close mobile menu after clicking menu items
  const [location, setLocation] = useState();
  const handleClick = (e) => setLocation(e.target.href);
  
  const [arr, setArr] = useState([]);
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  }
  
  useEffect(() => {
    // remove the selected item from languages array
    const clsLang = languages.map((e) => {
      if (e !== preSelectedLanguage) {
        return e;
      }
    });

    // remove the undefined item from array
    const restLang = clsLang.filter(function (element) {
      return element !== undefined;
    });

    // put the selected item on the start of array
    const data = [preSelectedLanguage, ...restLang];

    setArr(data);
  }, []);

  return (
    <>
      <Sticky
        enabled="true"
        stickyClassName="nav-sticky"
        css={css(styles(theme).wrapper)}
      >
        <Container variant="full" className="nav-container">
          <Container>
            <Flex sx={(styles(theme)).header}>
              <Box sx={styles(theme).logoContainer}>
                <GLink to="/">
                  <Img
                    image={getImage(images?.[0]?.src)}
                    alt={images?.[0]?.alt}
                    css={css(styles(theme).logo)}
                  />
                </GLink>
              </Box>
              {collection && (
                <>
                  <Box sx={styles(theme).desktopMenu}>
                    <Reveal effect="fadeInDown">
                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: menuJustify,
                          mr: 1,
                        }}
                      >
                        {collection.map(
                          ({ buttons }, index) =>
                            buttons && (
                              <Box key={`item-${index}`} m="3">
                                <ContentButtons
                                  space={0}
                                  content={buttons}
                                />
                              </Box>
                            )
                          )
                        }
                        <Select
                          onChange={(e) => {
                            switchLanguage(e.target.value);
                          }}
                          sx={{
                            width: 55,
                            fontSize: 14,
                            border: "none",
                            lineHeight: 1,
                            border: '1px solid',
                            ':focus-visible': {
                              // outline: 'none',
                            }
                          }}
                        >
                          {arr.map((lang) => {
                            return (
                              <option key={lang} value={lang}>
                                {langSelect[lang]?.name}
                              </option>
                            );
                          })}
                        </Select>
                      </Flex>
                    </Reveal>
                  </Box>
                  <Box sx={styles(theme).mobileMenu}>
                    <Drawer
                      locationState={location}
                      buttonStyle={{ svg: { size: 32 } }}
                    >
                      {collection.map(
                        ({ buttons }, index) =>
                          buttons && (
                            <Box
                              key={`item-${index}`}
                              sx={{ fontSize: 3 }}
                              m="3"
                            >
                              <ContentButtons
                                content={buttons}
                                space={0}
                                variant="vertical"
                                onClick={handleClick}
                              />
                            </Box>
                          )
                      )}
                      <div css={css(styles(theme).langsContainer)}>
                        <Select
                          onChange={(e) => {
                            switchLanguage(e.target.value);
                          }}
                          css={css(styles(theme).langs)}
                        >
                          {arr.map((lang) => {
                            return (
                              <option key={lang} value={lang}>
                                {langSelect[lang]?.name}
                              </option>
                            );
                          })}
                        </Select>
                      </div>
                    </Drawer>
                  </Box>
                </>
              )}
            </Flex>
          </Container>
        </Container>
      </Sticky>
    </>
  );
};

HeaderBlock01.defaultProps = {
  menuJustify: `flex-end`,
};

export default WithDefaultContent(HeaderBlock01);