import React from 'react'
import mergeWith from 'lodash.mergewith'
import { Text, Heading } from 'theme-ui'

const headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

const ContentText = ({ as: CustomComponent, content, children, ...props }) => {
  if (!content) return null

  const { text, ...contentRest } = content

  if (!children && text === null) return null

  const { variant, ...mergedProps } = mergeWith(
    {},
    props,
    contentRest,
    (a, b) => (b === null ? a : undefined)
  )

  const isHeading = headings.includes(variant)
  const innerHTML = <div style={{ textAlign: 'inherit' }} dangerouslySetInnerHTML={{ __html: text }} />;

  return isHeading ? (
    <Heading variant={variant} as={variant} {...mergedProps}>
      {children || innerHTML}
    </Heading>
  ) : (
    <Text variant={variant} {...mergedProps}>
      {children || innerHTML}
    </Text>
  )
}

export default ContentText
