import { Link as GLink } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Button, Link, useThemeUI } from 'theme-ui'
import AppButton from '@solid-ui-components/AppButton'
import VideoButton from '@solid-ui-components/VideoButton/VideoButton'

const isValidHttpUrl = link => {
  let url
  try {
    url = new URL(link)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

const buildLinkProps = ({
  content: { type, link, target, variant },
  location,
  setActiveModal,
  setActiveTab,
  authenticated,
  handleLogout,
}) => {
  const { theme } = useThemeUI();
  
  // Button or Text Link ?
  const isInternalLink = link && !isValidHttpUrl(link)
  const isLinkVariant = variant?.startsWith('links.')

  let linkProps
  let Component = isLinkVariant ? Link : Button

  switch (type) {
    case 'VIDEO':
      Component = VideoButton
      linkProps = { link }
      break
    case 'APP':
      Component = AppButton
      linkProps = { link }
      break
    case 'MODAL':
      linkProps = {
        onClick: () => setActiveModal(link)
      }
      break
    case 'TAB':
      linkProps = {
        tabindex: '0',
        onClick: () =>
          setActiveTab({
            identifier: link.split('.')[0],
            index: parseInt(link.split('.')[1])
          })
      }
      break
    case 'ANCHOR':
      if (!isInternalLink) {
        linkProps = { href: link, target, as: Link }
      } else {
        linkProps = { href: link, target, as: AnchorLink, offset: 150 }
      }
      break
    case 'SUBMIT':
      linkProps = { type: 'submit' }
      break
    case 'PAGE':
      const normalizedLink = link.endsWith('/') ? link.slice(0, -1) : link;
      const normalizedPathname = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
      const linkStyle = normalizedPathname === normalizedLink ? {
        color: `${theme.colors.alphaDark} !important`,
        fontSize: '18px !important',
        fontWeight: '600 !important'
      } : {};
      linkProps = {
        [isInternalLink ? 'to' : 'href']: link || undefined,
        as: isInternalLink ? GLink : 'a',
        target, sx: linkStyle
      }
      break
    // TODO: restructure LOGIN/LOGOUT buttons properly.
    case 'LOGIN':
      linkProps = {
        onClick: () => setActiveModal(link)
      };
      if (authenticated) {
        Component = null;
      };
      break;
    case 'LOGOUT':
      linkProps = {
        onClick: handleLogout
      };
      if (!authenticated) {
        Component = null;
      };
      break;
    default:
      linkProps = {}
      break
  }

  linkProps.className = isLinkVariant
    ? 'button-group-link'
    : 'button-group-button'

  return { Component, linkProps }
}

export default buildLinkProps
