import React, { useContext } from 'react'
import { Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import Modal from '@solid-ui-components/Modal'
import ContentForm from '@solid-ui-components/ContentForm'
import { ModalContext } from '@solid-ui-components/Modal'

const ModalBlock03 = ({ content }) => {
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const { identifier, kicker, title, text_1, text_2 } = content;

  if (activeModal && activeModal !== identifier) return null;

  const onSucess = async () => {
    await new Promise(resolve => setTimeout(resolve, 3000));
    setActiveModal(null);
  };

  return (
    <Modal
      id={identifier}
      contentStyles={{
        maxWidth: 500,
        p: 0,
      }}
    >
      <Flex
        sx={{
          alignItems: `stretch`,
          // flexDirection: [
          //   reverse ? `column-reverse` : `column`,
          //   reverse ? `row-reverse` : `row`
          // ]
        }}
      >
        {Array.isArray(content.collection) && (
          <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
            <StackTitle
              kicker={kicker}
              title={title}
              lines={[text_1, text_2]}
              space={3}
            />
              {content.collection
                ?.filter(Boolean)
                ?.map(
                  (
                    { kicker, title, text_1, text_2, form },
                    index
                  ) => (
                    <Reveal
                      key={`item-${index}`}
                      effect='fadeIn'
                      duration={0.5}
                      content={{ kicker }}
                    >
                      <StackTitle
                        title={title}
                        lines={[text_1, text_2]}
                        space={3}
                      />
                      {form && (
                        <ContentForm
                          form={form}
                          onSuccess={onSucess}
                          id={`form.${identifier}.${
                            form.multiStep ? 'multi' : index
                          }`}
                        />
                      )}
                    </Reveal>
                  )
                )}
          </Box>
        )}
      </Flex>
    </Modal>
  )
}

export default ModalBlock03
