import { useState, useEffect, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { AppContext } from '@helpers/appContext';
import { navigate } from 'gatsby';

const useForm = ({ onSuccess }) => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()
  const [formErrors, setFormErrors] = useState([]);

  const { handleLogin } = useContext(AppContext);

  const handleSubmit = (e, { values, action } = {}) => {
    e.preventDefault()
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }

  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()
    const isDemo = target.getAttribute('demo')

    // Mimicking form submission for demos
    if (isDemo) {
      setTimeout(() => {
        setSuccess(true)
        setSubmitting(false)
      }, 1500)
      return
    }

    // if Retry submit
    setFormErrors([]);

    //TODO: Should define this logic to some other place. B/c it is a common hook 
    if (action === 'userAuth.login') {
      handleLogin(values).then(() => {
        target.reset();
        setSuccess(true);
        setSubmitting(false);
        setTimeout(() => navigate('/downloads'), 2000);
      })
      .catch(() => {
        setSuccess(false);
        setSubmitting(false);
      });
    } else {
      // Real form submission
      fetch(action || target.action, {
        method: target.method || 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        },
        body: formData
      })
      .then(res => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            target.reset();
            setSuccess(true);
            setSubmitting(false);
            const respMsg = res?.body?.msg;
            toast.success(respMsg, { autoClose: 2000, position: "top-center" });
            onSuccess();
          } else {
            if (res.statusCode === 500) {
              setFormErrors([...formErrors, res.body]);
            } else if (res.statusCode === 400) {
              // form fields errors from server
              const errors = res.body;
              setFormErrors(errors);
            };
            setSuccess(false);
          };
        })
        .catch(error => {
          toast.error(error);
          setSuccess(false)
        })
        .finally(() => {
          setSubmitting(false);
        })
    }
  }, [target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
    setValues(undefined)
    setAction(undefined)
  }, [])

  return {
    handleSubmit,
    submitting,
    success,
    reset,
    formErrors
  }
}

export default useForm