import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    RespondToAuthChallengeCommand,
} from '@aws-sdk/client-cognito-identity-provider';

const client = new CognitoIdentityProviderClient({
  region: 'us-east-2'
});
const clientId = '12264mitqif3tce7776kob7ql';

const respondToNewPasswordChallenge = async (username, password, session) => {
  const params = {
    ChallengeName: 'NEW_PASSWORD_REQUIRED',
    ClientId: clientId,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
    Session: session,
  };

  const command = new RespondToAuthChallengeCommand(params);

  try {
    const response = await client.send(command);
    return response;
  } catch (error) {
    console.log('Error Password Challenge:', error);
    throw error;
  }
};

export const login = async (username, password) => {
  const params = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: clientId,
    // UserPoolId: 'us-east-2_yf4JwSJj9',
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };

  const command = new InitiateAuthCommand(params);
  let response = null;
  try {
    response = await client.send(command);

    // change password (using same) to complete Signup process (B/c we creating manual password at cognito console) 
    if(response?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
      response = await respondToNewPasswordChallenge(username, password, response.Session);
    };
    // Store refreshToken
    localStorage.setItem('refreshToken', response.AuthenticationResult.RefreshToken);

    return true;
  } catch (error) {
    console.log('Error Login:', error);
    throw error;
  };
};

export const auth = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    const params = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: clientId,
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    };
    const command = new InitiateAuthCommand(params);
    try {
      await client.send(command);
      return true;
    } catch (error) {
      console.log('Error Auth:', error);
      throw error;
    };
  } else {
    return false;
  };
};

export const logOut = async (tokenInvalid = false) => {
    localStorage.removeItem('refreshToken');
    if (!tokenInvalid) {
      // For smooth UI
      await new Promise(resolve => setTimeout(resolve, 500));
    };
    return true;

  // GlobalSignOutCommand does not works without credentials (which I think used for Admin/Developer use)
  //   const refreshToken = localStorage.getItem('refreshToken');
  //   const accessToken = localStorage.getItem('accessToken');
  //   // const expirationTime = localStorage.getItem('expirationTime');
  //   if (!refreshToken || !accessToken) {
  //     return false;
  //   };
  //   const params = {
  //     AccessToken: accessToken,
  //     SessionToken: accessToken
  //     // UserPoolId: 'us-east-2_yf4JwSJj9',
  //     // Username: 'maqsood-ahmed'
  //   };
  //   console.log('here logout params => ', params)
  //   const command = new GlobalSignOutCommand(params);
  //   try {
  //     await client.send(command);
  //     localStorage.removeItem('refreshToken');
  //     localStorage.removeItem('accessToken');
  //     localStorage.removeItem('expirationTime');
  //     return true;
  //   } catch (error) {
  //     console.log('Error Logout:', error);
  //     throw error
  //   };
};